import React from 'react';
import {Button, Form, Input, notification} from 'antd';
import {instance} from 'instance';

const Auth = ({setIsAuth}) => {
  const onFinish = async (values) => {
    try {
      const {data} = await instance.post('/dashboard/login', {
        telegramNick: values.username,
        password: values.password,
      });
      notification.success({message: 'Вы вошли'});
      localStorage.setItem('token', data);
      setIsAuth(true);
    } catch (e) {
      console.error(e);
      notification.error({message: 'Ошибка входа'});
    }
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Form
        name="basic"
        labelCol={{span: 8}}
        wrapperCol={{span: 16}}
        initialValues={{remember: true}}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="Логин"
          name="username"
          rules={[{required: true, message: 'Укажите логин'}]}
        >
          <Input autoComplete='notificationLogin' />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{required: true, message: 'Укажите пароль'}]}
        >
          <Input.Password autoComplete='notificationPassword' />
        </Form.Item>

        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button style={{width: '100%'}} type="primary" htmlType="submit">
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Auth;
