import {Button, Input, notification as notify} from 'antd';
import {instance} from 'instance';
import React, {useState} from 'react';

export const InlineCreateNotification = ({
  handleCreateCallback,
  buttonDisabled,
}) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);


  const handleSubmit = async () => {
    try {
      const {data: newNotification} = await instance
          .post('/dashboard/notification/edit', {
            'id': -1,
            'subject': title,
            'message': description,
            'answers': [],
            'attachments': [],
          });

      console.log('newNotification', newNotification);
      handleCreateCallback && handleCreateCallback(newNotification);

      /* reset values */
      setTitle('');
      setDescription('');
    } catch (e) {
      console.error(e);
      notify.error({message: 'Произошла ошибка'});
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className='notification__inline-create'
    >
      <Input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        disabled={loading}
        style={{marginBottom: 32}}
        placeholder='Введите заголовок'
      />
      <Input.TextArea
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        disabled={loading}
        placeholder='Введите сообщение'
      />

      <Button
        type='primary'
        onClick={handleSubmit}
        disabled={loading || buttonDisabled}
        loading={loading}
        style={{marginTop: 16, width: '100%'}}
      >
          Создать и отправить
      </Button>
    </div>
  );
};
