import qs from 'query-string';

export const handleSearch = (history) => (value) => {
  const parsedSearh = qs.parse(history.location.search);
  parsedSearh['query'] = value;
  history.push(history.location.pathname + `?${qs.stringify(parsedSearh)}`);
};


export const downloadFile = (path) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = path.split('/').slice(-1);

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};
