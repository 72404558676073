
import React, {useRef} from 'react';
import {MyTable} from 'UI/Table';
import {Title} from 'UI/Title';
import {useRender} from './useRender';

const Planned = () => {
  const tableRef = useRef();

  const {
    renderActions,
    renderRecipients,
    renderPeriod,
  } = useRender({tableRef});

  return (
    <div>
      <Title>
            Запланированные уведомления
      </Title>
      <MyTable
        ref={tableRef}
        bordered
        columns={[
          {
            title: 'Тема',
            key: 'subject',
            render: (_, record) => record.notification.subject,
            width: 260,
          },
          {
            title: 'Кому',
            render: renderRecipients,
            key: 'name',
            width: 400,
          },
          {
            title: 'Периодичность',
            render: renderPeriod,
            key: 'email',
          },
          {
            title: 'Действие',
            key: 'action',
            render: renderActions,
            width: 100,
          },
        ]}
        endpoint='/dashboard/planned'
        dataField='results'
      />

    </div>
  );
};

export default Planned;
