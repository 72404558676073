import {Col, Modal, Row, Spin, notification as notify} from 'antd';
import {NotificationItem} from 'Components/NotificationItem/NotificationItem';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';
import 'moment/locale/ru';
import {Parametres} from './Parametres';
import {WithSectionName} from 'hoc/WithSectionName';
import {useHandleNotifications} from 'Hooks/useHandleNotifications';
import {postNotification} from 'api/notifications';
import {
  InlineCreateNotification,
} from 'Components/NotificationItem/InlineCreateNotification';

export const SendNoificationModal = ({open, setOpen, structureToMerge}) => {
  const [loading, setLoading] = useState({});
  const [dataFetching, setDataFetching] = useState(true);
  const [notifications, setNotifications] = useState([]);

  const {
    parametres,
    handleChange,
    handleDateChange,
    resetParametres,
    handleAddDateChange,
    handleAddNewDate,
    handleRemoveDate,
  } = useHandleNotifications();

  const handleSend = (notification) => async () => {
    console.log(notification);
    if (!parametres.date[0]) {
      notify.error({message: 'Выберите дату'});
      return 1;
    }

    const defaultObject = {
      'notificationId': notification.id,
      'period': {
        'dates': parametres.date.map(
            (item) => item.format('YYYY-MM-DD[T]HH:mm:ss'),
        ),
        'periodInDays': parametres.isRepeat ?
        parametres.period :
        0,
        'lastDate': parametres.isEnd ?
        parametres.lastDate.format('YYYY-MM-DD[T]HH:mm:ss') :
        null,
        'isWeekendIncluded': parametres.isWeekendIncluded,
        'send_just_now': parametres.send_just_now,
      },
      'target': {
        'employees': [],
        'group': [],
      },
    };

    /* лоадер на конкретную уведомлялку */
    setLoading({[notification.id]: true});

    await postNotification(
        Object.assign(defaultObject, structureToMerge),
        undefined,
        () => setLoading({}),
    );
  };

  const getData = async () => {
    try {
      setDataFetching(true);
      const {data} = await instance.get('/dashboard/notification');
      setNotifications(data.results);
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    resetParametres();
  };

  return (
    <Modal
      title="Отправить уведомление"
      visible={open}
      footer={null}
      width={800}
      onCancel={handleClose}
    >
      {open && (
        <div style={{marginBottom: 24}} >
          <Parametres
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            parametres={parametres}
            handleAddDateChange={handleAddDateChange}
            handleAddNewDate={handleAddNewDate}
            handleRemoveDate={handleRemoveDate}
          />
        </div>
      )}

      <WithSectionName
        title='Уведомления'
        orientation='left'
      >
        {dataFetching ? <Spin size='large' /> :
        <Row gutter={[24, 24]}>
          <Col span={12} >
            <InlineCreateNotification
              buttonDisabled={!parametres.date[0]}
              handleCreateCallback={(notification) => {
                handleSend(notification)();
              }}
            />
          </Col>
          {notifications.map((notification) => (
            <Col key={notification.id} span={12} >
              <NotificationItem
                notification={notification}
                loading={loading[notification.id]}
                handleSend={handleSend}
                noSendButton
              />
            </Col>
          ))}
        </Row>
        }
      </WithSectionName>
    </Modal>
  );
};
