export const dateformat = 'DD.MM.YYYY HH:mm:ss';

let isAuthorized = false;

setTimeout(() => {
  console.log('changed');
  isAuthorized = true;
}, 5000);

export {isAuthorized};
