import {instance} from 'instance';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router';

export const useTableSearch = ({
  endPoint,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [data, setData] = useState([]);

  const getData = async () => {
    try {
      setLoading(true);
      const {data} = await instance.get(
          endPoint + history.location.search,
      );
      setLoading(false);

      setData(data.results);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };


  useEffect(() => {
    getData();
  }, [history.location.search]);

  useEffect(() => {
    const unlisten = history.listen(forceUpdate);
    return (() => {
      unlisten();
    });
  }, []);

  return ({
    loading,
    data,
    getData,
  });
};
