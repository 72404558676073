
import React from 'react';
import Auth from 'Pages/Auth/Auth';
import {Route} from 'react-router';
import {SUCCESS_AUTH} from 'store/authStore';

export const WithAuth = ({children, flag=SUCCESS_AUTH, setIsAuth}) => {
  if (!flag) {
    return (
      <Route>
        <Auth setIsAuth={setIsAuth} />
      </Route>
    );
  }

  return children;
};
