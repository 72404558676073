import {green} from '@ant-design/colors';
import {Button, Input, notification as notify} from 'antd';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Title} from 'UI/Title';
import {Answers} from './Blocks/Answers';
import {Files} from './Blocks/Files';

const NotificationModification = () => {
  const history = useHistory();
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    id: -1,
    attachments: [],
    answers: [],
    subject: '',
    message: '',
  });

  const handleCreate = async () => {
    try {
      setLoading(true);
      const notificationDataCopy = JSON.parse(JSON.stringify(notification));
      notificationDataCopy['attachments'] = notificationDataCopy['attachments']
          .map((att) => att.id);
      await instance
          .post('/dashboard/notification/edit', notificationDataCopy);
      if (id === -1 || id === '-1') {
        notify.success({message: 'Уведомление создано'});
        history.push('/notifications');
      } else {
        notify.success({message: 'Уведомление отредактировано'});
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleTextChange = (e) => {
    setNotification((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };

  const getData = async () => {
    if (id === -1 || id === '-1') return;
    try {
      setLoading(true);
      const {data} = await instance.get(`/dashboard/notification/${id}`);
      setNotification({
        ...data,
        answers: data.answers.map((i) => i.id),
      });
    } catch {
      notify.error({message: 'Неизвестная ошибка'});
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id !== -1) {
      getData();
    }
  }, []);

  return (
    <div>
      <Title
        aside={
          <Button
            size='large'
            style={{
              background: green.primary,
              color: 'white',
              width: 200,
            }}
            disabled={loading}
            onClick={handleCreate}
          >
            {loading ? 'Подождите...' : 'Сохранить'}
          </Button>
        }
      >
        {id > -1 ? 'Изменить' : 'Создать'} оповещение
      </Title>

      <Input
        placeholder="Заголовок оповещения"
        allowClear
        value={notification?.subject || ''}
        onChange={handleTextChange}
        size='large'
        style={{width: 540, marginBottom: 24}}
        name='subject'
      />

      <Input.TextArea
        rows={8}
        placeholder='Сообщение оповещения'
        value={notification?.message || ''}
        onChange={handleTextChange}
        name='message'
      />


      <Files
        notification={notification}
        setNotification={setNotification}
      />

      <Answers
        handleCreate={handleCreate}
        notification={notification || {}}
        setNotification={setNotification}
      />

    </div>
  );
};

export default NotificationModification;
