import 'antd/dist/antd.css';
import {Layout} from 'antd';
import './fonts/fonts.css';
import './App.css';
import React, {useState} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import Users from 'Pages/Users/Users';
import Groups from 'Pages/Groups/Groups';
import GroupModification from 'Pages/GroupModification/GroupModification';
import NotificationsPage from 'Pages/NotificationsPage/NotificationsPage';
// eslint-disable-next-line max-len
import NotificationModification from 'Pages/NotificationModification/NotificationModification';
import Results from 'Pages/Results/Results';
import ResultsDetails from 'Pages/ResultDetails/ResultsDetails';
import {Sidebar} from 'Components/Sidebar/Sidebar';
import {WithAuth} from 'hoc/WithSectionName/WithAuth/WithAuth';
import {Navbar} from 'Components/Navbar/Navbar';
import Planned from 'Pages/Planned/Planned';

const {Content, Footer} = Layout;

function App() {
  const [isAuth, setIsAuth] = useState(localStorage.getItem('token'));

  return (

    <Layout style={{minHeight: '100vh'}} >

      <Switch>
        <WithAuth setIsAuth={setIsAuth} flag={isAuth} >
          <Route path='/'>
            <Sidebar />

            <Layout style={{minHeight: '100vh'}}>

              <Navbar setIsAuth={setIsAuth} />

              <Content style={{margin: '24px 16px 0'}}>
                <div
                  className="site-layout-background"
                  style={{padding: 24, minHeight: 360}}
                >
                  <Route exact path={['/users/:employeeId', '/users']}>
                    <Users />
                  </Route>
                  <Route exact path='/groups'>
                    <Groups />
                  </Route>
                  <Route path='/groups/:id'>
                    <GroupModification />
                  </Route>
                  <Route exact path='/notifications'>
                    <NotificationsPage />
                  </Route>
                  <Route path='/notifications/:id'>
                    <NotificationModification />
                  </Route>
                  <Route exact path='/results'>
                    <Results />
                  </Route>
                  <Route path='/results/:id'>
                    <ResultsDetails />
                  </Route>
                  <Route path='/planned'>
                    <Planned />
                  </Route>
                </div>
              </Content>

              <Footer
                style={{textAlign: 'center'}}
              >
                Famedev ©2021
              </Footer>

            </Layout>
          </Route>
        </WithAuth>
        <Route>
          <Redirect to={isAuth ? '/users' : '/auth'} />
        </Route>
      </Switch>
    </Layout>
  );
}

export default App;
