import {cyan, green} from '@ant-design/colors';
import {Button, Space} from 'antd';
import {
  SendNoificationModal,
} from 'Components/SendNotificationModal/SendNotificationModal';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {MyTable} from 'UI/Table';
import {Title} from 'UI/Title';


const Groups = () => {
  const [open, setOpen] = useState(false);
  const [structureToMerge, setStructureToMerge] = useState({});
  const history = useHistory();

  const handleOpen = (id) => () => {
    setStructureToMerge({target: {'group': [id], 'employees': []}});
    setOpen(true);
  };

  const handleCreateClick = () => history.push('/groups/-1');

  const renderEmployees = (text, record) =>
    record.employees.map((emp) => emp.name).join(', ');

  const renderActions = (text, record) => (
    <Space size="middle">
      <Button
        type='primary'
        onClick={handleOpen(record.id)}
      >
          Отправить уведомление
      </Button>
      <Button
        type='primary'
        style={{background: cyan.primary, border: cyan.primary}}
        onClick={() => history.push(`/groups/${record.id}`)}
      >
          Редактировать
      </Button>
    </Space>
  );

  return (
    <div>
      <Title
        aside={
          <Button
            size='large'
            style={{background: green.primary, color: 'white', width: 200}}
            onClick={handleCreateClick}
          >
          Создать
          </Button>
        }
      >
        Группы
      </Title>

      <MyTable
        columns={[
          {
            title: 'Название',
            key: 'name',
            dataIndex: 'name',
          },
          {
            title: 'Сотрудники',
            render: renderEmployees,
            key: 'employees',
          },
          {


            title: 'Действие',
            key: 'action',
            render: renderActions,
          },
        ]}
        endpoint='/dashboard/group'
        dataField='results'
      />

      <SendNoificationModal
        structureToMerge={structureToMerge}
        open={open}
        setOpen={setOpen}
      />

    </div>
  );
};

export default Groups;
