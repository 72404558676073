import {instance} from 'instance';

export const getAllFiles = async () =>
  instance.get('/dashboard/notification/attachment/search');

export const uploadFile = async (file) =>{
  const body = new FormData();
  body.append('icon', file);
  return instance.post('/dashboard/notification/attachment', body);
};

export const deleteFile = async (path) =>
  instance.delete('/dashboard/notification/attachment', {data: {path}});
