
export const WAITING_FOR_AUTH = -1;
export const NOT_AUTH = 0;
export const SUCCESS_AUTH = 1;

class Auth {
}


export default new Auth();
