import {notification, Spin} from 'antd';
import Text from 'antd/lib/typography/Text';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {Title} from 'UI/Title';
import {ResultFiles} from './ResultFiles';
import {ResultsAnswers} from './ResultsAnswers';

const ResultsDetails = () => {
  const {id} = useParams();

  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    try {
      setLoading(true);
      const {data} = await instance.get(`/dashboard/results/${id}`);
      setResult(data);
    } catch {
      notification['error']({
        message: 'Произошла неизвестная ошибка',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <div style={{
      display: 'flex', justifyContent: 'center',
      marginTop: 300,
    }} >
      <Spin size='large' />
    </div>;
  }

  return (
    <div>
      <Title>
        {result.title}
      </Title>

      <Text type='secondary'>
          Уведомление:
      </Text>
      <br/>
      <Text>
        {result.notification.message}
      </Text>

      <ResultFiles notification={result.notification} />

      <ResultsAnswers result={result} />

    </div>
  );
};

export default ResultsDetails;
