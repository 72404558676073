import {useCallback, useEffect, useState} from 'react';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';
import {instance} from 'instance';

export const useHandleTable = ({endPoint='', withGetData, dataField}) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [pagination, setPagination] = useState({
    current: qs.parse(history.location.search)?.page || 1,
    pageSize: qs.parse(history.location.search)?.pageSize || 10,
  });

  const offset = qs.parse(history.location.search)['offset'] || 0;
  const limit = qs.parse(history.location.search)['limit'] || 10;

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);

  const getData = async () => {
    try {
      const parsedSearch = qs.parse(history.location.search);
      parsedSearch['limit'] = parsedSearch['limit'] || 10;
      parsedSearch['offset'] = parsedSearch['offset'] || 0;

      setLoading(true);
      const {data} = await instance.get(
          endPoint + '?' + qs.stringify(parsedSearch),
      );
      setLoading(false);

      setAllData(data);
      setData(data[dataField || 'results']);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };


  useEffect(() => {
    if (withGetData) {
      getData();
    }
  }, [history.location.search]);

  useEffect(() => {
    const unlisten = history.listen(forceUpdate);
    return (() => {
      unlisten();
    });
  }, []);

  return ({
    loading,
    setLoading,
    pagination,
    setPagination,
    data,
    setData,
    offset,
    limit,
    getData,
    allData,
  });
};
