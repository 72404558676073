import {Menu, Layout} from 'antd';
import React, {useRef} from 'react';
import {
  UserOutlined,
  TeamOutlined,
  NotificationOutlined,
  PieChartOutlined,
  FieldTimeOutlined,
} from '@ant-design/icons';
import {useHistory} from 'react-router';
import logo from 'img/logo.png';

const {Sider} = Layout;

export const Sidebar = () => {
  const history = useHistory();

  const handleMenuClick = (link) =>() => {
    history.push(link);
  };

  const menu = useRef([
    {title: 'Сотрудники', link: '/users', icon: <UserOutlined />},
    {title: 'Группы', link: '/groups', icon: <TeamOutlined />},
    {
      title: 'Уведомления',
      link: '/notifications',
      icon: <NotificationOutlined />,
    },
    {
      title: 'Результаты',
      link: '/results',
      icon: <PieChartOutlined />,
    },
    {
      title: 'Запланированные',
      link: '/planned',
      icon: <FieldTimeOutlined />,
    },
  ]);

  return (
    <Sider
      breakpoint="lg"
      theme='light'
      collapsedWidth="0"
    >
      <div className="logo" >
        <img src={logo} alt='Инбис+' style={{height: 50}} />
      </div>
      <Menu
        style={{position: 'sticky', top: 82}}
        theme="light"
        mode="inline"
        defaultSelectedKeys={[`/${history.location.pathname.split('/')[1]}`]}
      >
        {menu.current.map((item) => (
          <Menu.Item
            onClick={handleMenuClick(item.link)}
            key={item.link}
            icon={item.icon}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};
