import {Card, Col, Divider, Row, Typography} from 'antd';
import React from 'react';
import {
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
} from '@ant-design/icons';

export const NotificationItem = ({
  notification,
  handleEdit,
  handleDelete,
  handleSend,
  handleSendNotification,
  loading,
  noSendButton,
}) => {
  return (
    <Card
      title={notification.subject}
      loading={loading}
      onClick={handleSend && handleSend(notification)}
      hoverable
      actions={handleEdit ? [
        <EditOutlined
          key={'edit'}
          onClick={() => handleEdit(notification.id)}
        />,
        <DeleteOutlined
          key={'delete'}
          onClick={() => handleDelete(notification.id)}
        />,
        noSendButton ? null :
        <SendOutlined
          key={'send'}
          onClick={() => handleSendNotification(notification.id)}
        />,
      ] : null}
    >
      <Typography>
        {notification.message}
      </Typography>

      <Divider />

      <Row gutter={[8, 8]} >
        {notification.answers.map((ans) => (
          <Col key={ans.id}>
            <div style={{
              padding: 8,
              border: '1px solid #bfbfbf',
            }} >
              {ans.name}
            </div>
          </Col>
        ))}
      </Row>

    </Card>
  );
};
