import {Typography} from 'antd';
import React from 'react';

const {Paragraph} = Typography;

export const WithEllipsis = ({children}) => {
  return (
    <Paragraph
      ellipsis={{rows: 2, expandable: true, symbol: 'развернуть'}}
    >
      {children}
    </Paragraph>
  );
};
