import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ruRU from 'antd/lib/locale/ru_RU';
import {BrowserRouter as Router} from 'react-router-dom';
import {ConfigProvider} from 'antd';

ReactDOM.render(
    <Router>
      <ConfigProvider locale={ruRU} >
        <App />
      </ConfigProvider>
    </Router>,
    document.getElementById('root'),
);
