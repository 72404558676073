import {Button, Popconfirm, Space} from 'antd';
import React, {useRef} from 'react';
import {useHistory} from 'react-router';
import {MyTable} from 'UI/Table';
import {Title} from 'UI/Title';
import * as dayjs from 'dayjs';
import {instance} from 'instance';
import {dateformat} from '_constants';
import {cyan} from '@ant-design/colors';

const Results = () => {
  const history = useHistory();

  const table = useRef(null);

  const repeat = (id) => async () => {
    await instance.post(`/dashboard/results/${id}/repeat`);
    table.current.getData();
  };

  const decline = (id) => async () => {
    await instance.post(`/dashboard/results/${id}/cancel`);
    table.current.getData();
  };

  const renderActions = (text, record) => {
    return (
      <Space size='middle' >
        <Popconfirm
          title={
            !record.isPlanned ? 'Отменить уведомление?' : 'Отправить повторно?'
          }
          onConfirm={record.isPlanned ? repeat(record.id) : decline(record.id)}
        >
          {record.isPlanned ?
          (
            <Button style={{width: 102}} type="primary">
              Повторить
            </Button>
          ) :
        (
          <Button style={{width: 102}} type="primary" danger>
          Отменить
          </Button>
        )
          }
        </Popconfirm>
        <Button
          type='primary'
          style={{background: cyan.primary, border: cyan.primary}}
          onClick={() => history.push(`/results/${record.id}`)}
        >
          Подробнее
        </Button>
      </Space>
    );
  };

  return (
    <div>
      <Title>
        Результаты
      </Title>

      <MyTable
        ref={table}
        columns={[
          {
            title: 'Дата',
            key: 'name',
            render: (text, record) => (
              dayjs(record.date).format(dateformat)
            ),
          },
          {
            title: 'Тема',
            render: (text, record) => record.notification.subject,
            key: 'employees',
          },
          {
            title: 'Кому',
            dataIndex: 'targetGroup',
            key: 'targetGroup',
          },
          {
            title: 'Ответы',
            render: (_, record) => (
              record.isPlanned ? 'Планируется' :
              record.answersRequested ?
              `${record.answersReceived}/${record.answersRequested}` :
              'Нет ответов'
            ),
            key: 'employees',
          },
          {
            title: 'Действие',
            key: 'action',
            render: renderActions,
          },
        ]}
        endpoint='/dashboard/results'
        dataField='results'
      />

    </div>
  );
};

export default Results;
