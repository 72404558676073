import {Spin, Transfer} from 'antd';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';

export const Recipient = ({
  data,
  setData,
  endPoint,
}) => {
  const [dataFetching, setDataFetching] = useState(true);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const onnChange = (nextTargetKeys) => {
    setData(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const [dataSource, setDataSource] = useState([]);

  const getData = async () => {
    try {
      setDataFetching(true);
      const {data} = await instance.get(endPoint);
      setDataSource(data.results);
    } catch (e) {
      console.error(e);
    } finally {
      setDataFetching(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (dataFetching) {
    return (
      <div style={{
        marginTop: 32, display: 'flex', justifyContent: 'center',
      }} >
        <Spin size='large' />
      </div>
    );
  }

  return (

    <Transfer
      dataSource={dataSource}
      titles={['Не отмечены', 'Отмечены']}
      targetKeys={data}
      selectedKeys={selectedKeys}
      onChange={onnChange}
      rowKey={(record) => record.id}
      onSelectChange={onSelectChange}
      pagination
      filterOption={(inputValue, option) => {
        if (inputValue) {
          return option.name.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return true;
        }
      }}
      showSearch
      oneWay
      render={(item) => item.name}
    />
  );
};
