import {
  Modal,
  notification as notify,
  Tabs,
} from 'antd';
import React, {useState} from 'react';
import 'moment/locale/ru';
import {Recipient} from './Recipient';
import {Parametres} from 'Components/SendNotificationModal/Parametres';
import {useHandleNotifications} from 'Hooks/useHandleNotifications';
import {postNotification} from 'api/notifications';

export const SendDirectNotificationModal = ({
  notificationId,
  setNotificationId,
}) => {
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    parametres,
    handleChange,
    handleDateChange,
    resetParametres,
    handleAddDateChange,
    handleAddNewDate,
    handleRemoveDate,
  } = useHandleNotifications();

  const handleClose = () => {
    setGroups([]);
    setUsers([]);
    setLoading(false);
    resetParametres();
    setNotificationId(null);
  };

  const handleSend = async () => {
    if (!parametres.date[0]) {
      notify.error({message: 'Выберите дату'});
      return 1;
    }
    if (!users.length && !groups.length) {
      notify.error({message: 'Выберите получателя'});
      return 1;
    }

    const sendObject = {
      'notificationId': notificationId,
      'period': {
        'dates': parametres.date.map(
            (item) => item.format('YYYY-MM-DD[T]HH:mm:ss'),
        ),
        'periodInDays': parametres.isRepeat ? parametres.period : 0,
        'lastDate': parametres.isEnd ?
        parametres.lastDate.format('YYYY-MM-DD[T]HH:mm:ss') :
        null,
        'isWeekendIncluded': parametres.isWeekendIncluded,
        'send_just_now': parametres.send_just_now,
      },
      'target': {
        'employees': users,
        'group': groups,
      },
    };


    setLoading(true);

    await postNotification(
        sendObject,
        () => {
          setNotificationId(null);
          setLoading(false);
        },
        () => setLoading(false),
    );
  };


  return (
    <Modal
      title="Отправить уведомление"
      visible={!!notificationId}
      onOk={handleSend}
      okButtonProps={{disabled: loading}}
      okText={loading ? 'Подождите...' : 'Отправить'}
      width={800}
      onCancel={handleClose}
    >
      {!!notificationId && (
        <div style={{marginBottom: 24}} >
          <Parametres
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            parametres={parametres}
            handleAddDateChange={handleAddDateChange}
            handleAddNewDate={handleAddNewDate}
            handleRemoveDate={handleRemoveDate}
          />
        </div>
      )}

      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Групппы" key="1">
          <Recipient
            data={groups}
            setData={setGroups}
            endPoint={'/dashboard/group'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Сотрудники" key="2">
          <Recipient
            data={users}
            setData={setUsers}
            endPoint={'/dashboard/employees'}
          />
        </Tabs.TabPane>
      </Tabs>

    </Modal>
  );
};
