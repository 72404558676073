/* eslint-disable max-len */
import {Input, Modal, notification, Form, InputNumber} from 'antd';
import {editEmployee} from 'api/employee';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';

export const UserModification = ({tableRef}) => {
  const history = useHistory();

  const {employeeId} = useParams();
  const [employee, setEmployee] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm(); /* ссылка на форму */

  const getData = async () => {/* получение данных сотрудника */
    try {
      const {data} = await instance.get(`/dashboard/employees/${employeeId}`);
      setEmployee(data);
      form.setFieldsValue(data);
    } catch (e) {
      console.error(e);
      notification.error({message: 'Неизвестная ошибка'});
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    history.push({
      pathname: '/users',
      search: history.location.search,
    });
  };

  const handleOk = () => {
    form.validateFields()/* Валидация полей */
        .then((values) => {/* Если ок, то запрос */
          setLoading(true);
          editEmployee({...values, id: employeeId})
              .then(() => {/* Если ок запрос, то обновляем таблицу и чистим поля */
                form.resetFields();
                setLoading(false);
                tableRef.current.getData();
                handleClose();
              })
              .catch(() => {/* Иначе ошибка */
                setLoading(false);
                notification.error({message: 'Ошибка сервера'});
              });
        })
        .catch((info) =>{
          notification.error({message: 'Произошла ошибка'});
        });
  };

  useEffect(() => {
    if (history.location.state?.employee) {/* Если пришли с таблицы, то просто данные в стейт */
      setEmployee(history.location.state.employee);
      form.setFieldsValue(history.location.state.employee);
    } else {/* иначе, запрашиваем с сервера */
      if (employeeId) {
        getData();
      }
    }
  }, [employeeId]);

  const isVisible = employeeId !== undefined; /* Открыта ли модалка */

  return (
    <Modal
      title={employeeId === '-1' ?
      'Создание' :
      'Редактирование' + ' ' + 'сотрудника'}
      visible={isVisible}
      onCancel={handleClose}
      onOk={handleOk}
      cancelButtonProps={{disabled: loading}}
      okButtonProps={{disabled: loading}}
      okText='Сохранить'
    >
      <Form
        key={employee}
        form={form}
        labelCol={{span: 6}}
        wrapperCol={{span: 14}}
        scrollToFirstError
        layout="vertical"
        size={'middle'}
      >
        <Form.Item
          label="ФИО"
          name="name"
          rules={[{
            required: true,
            message: 'Укажите ФИО',
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Почта"
          name="email"
          rules={[
            {
              required: true,
              message: 'Укажите почту',
            },
            {
              type: 'email',
              message: 'Неверный формат почты',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Ник в ТГ"
          name="telegramNick"
          rules={[{
            required: true,
            message: 'Укажите ник ',
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="ID в ТГ"
          name="telegramID"
          rules={[
            {
              required: true,
              message: 'Укажите ID чата ',
            },
            {
              type: 'number',
              message: 'Состоит из цифр',
            },
          ]}
        >
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
        <Form.Item
          label="ID чата в ТГ"
          name="telegramChatID"
          rules={[
            {
              required: true,
              message: 'Укажите ID чата ',
            },
            {
              type: 'number',
              message: 'Состоит из цифр',
            },
          ]}
        >
          <InputNumber style={{width: '100%'}} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
