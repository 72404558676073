import {Button, Layout, notification} from 'antd';
import React from 'react';

const {Header} = Layout;

export const Navbar = ({setIsAuth}) => {
  const handleLogout = () => {
    localStorage.removeItem('token');
    notification.success({message: 'Вы вышли из системы'});
    setIsAuth(null);
  };

  return (
    <Header
      className="site-layout-sub-header-background"
      style={{
        padding: 16,
        background: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        type='ghost'
        danger
        onClick={handleLogout}
        style={{margin: '0 0 0 auto', alignSelf: 'center'}}
      >
            Выйти
      </Button>
    </Header>
  );
};
