import Search from 'antd/lib/input/Search';
import React, {useEffect, useState} from 'react';
import {instance} from 'instance';
import {Transfer} from 'antd';
import {WithSectionName} from 'hoc/WithSectionName';

export const Answers = ({notification, setNotification, handleCreate}) => {
  const [allAnswers, setAllAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAnswers = async () => {
    try {
      const {data} = await instance.get('/dashboard/notification/answer');
      setAllAnswers(data);
    } catch (e) {
      console.error(e);
    }
  };

  const postCreateAnswer = async (name) => {
    try {
      setLoading(true);
      const {data} = await instance
          .post('/dashboard/notification/answer/add', {id: -1, name});
      getAnswers();
      setNotification((pDt) => ({
        ...pDt,
        answers: [...pDt.answers, data.id],
      }));
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAnswers();
  }, []);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const onnChange = (nextTargetKeys) => {
    setNotification((prevData) => ({
      ...prevData,
      answers: nextTargetKeys,
    }));
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <WithSectionName
      title='Ответы'
      orientation='left'
    >
      <div>
        <Search
          placeholder="Введите текст ответа"
          enterButton="Создать"
          size="large"
          loading={loading}
          onSearch={postCreateAnswer}
          style={{marginBottom: 24}}
        />

        <Transfer
          dataSource={allAnswers}
          titles={['Не в группе', 'В группе']}
          targetKeys={notification.answers}
          selectedKeys={selectedKeys}
          onChange={onnChange}
          filterOption={(inputValue, option) => {
            if (inputValue) {
              return option.name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase());
            } else {
              return true;
            }
          }}
          rowKey={(record) => record.id}
          onSelectChange={onSelectChange}
          pagination
          showSearch
          oneWay
          render={(item) => item.name}
        />
      </div>
    </WithSectionName>
  );
};

