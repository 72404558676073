import {Space} from 'antd';
import {SingleFile} from 'Common/SingleFile/SingleFile';
import React from 'react';
import {downloadFile} from 'utils/_uncategorized';

export const ResultFiles = ({notification}) => {
  const handleDownloadFile = (file) => {
    downloadFile(file);
  };

  return (
    <div style={{marginLeft: -24, width: 'calc(100% - 24px)'}} >
      <Space size='middle' >
        {notification?.attachments?.map?.((item) => (
          <SingleFile
            file={item}
            key={item}
            onSelect={handleDownloadFile}
          />
        ))}
      </Space>
    </div>
  );
};
