import {Transfer} from 'antd';
import React, {useState} from 'react';

export const UsersTransfer = ({
  setGroup,
  group,
  allUsers,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);

  const onnChange = (nextTargetKeys) => {
    setGroup((prevData) => ({
      ...prevData,
      employees: nextTargetKeys,
    }));
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <Transfer
      dataSource={allUsers}
      titles={['Не в группе', 'В группе']}
      targetKeys={group.employees}
      selectedKeys={selectedKeys}
      onChange={onnChange}
      filterOption={(inputValue, option) => {
        if (inputValue) {
          return option.name.toLowerCase().includes(inputValue.toLowerCase());
        } else {
          return true;
        }
      }}
      rowKey={(record) => record.id}
      onSelectChange={onSelectChange}
      pagination
      showSearch
      oneWay
      render={(item) => item.name}
    />
  );
};
