import {instance} from 'instance';
import {notification as notify} from 'antd';

export const postNotification = async (body, callback, finallyCallback) => {
  const bodyCopy = JSON.parse(JSON.stringify(body));

  try {
    if (!bodyCopy?.period?.lastDate) {
      delete bodyCopy?.period?.lastDate;
    }

    await instance
        .post(
            '/dashboard/notification/send',
            bodyCopy,
        );

    callback && callback();
    notify.success({message: 'Уведомление отправлено'});
  } catch (e) {
    console.error(e);
    notify.error({message: 'Произошла ошибка'});
  } finally {
    finallyCallback && finallyCallback();
  }
};


export const deleteNotificationRequest = async (notificationId) => {
  try {
    await instance
        .post(`/dashboard/notification/${notificationId}/delete`);
    notify.success({message: 'Уведомление удалено'});
    return;
  } catch (e) {
    console.error(e);
    notify.error({message: 'Произошла ошибка'});
  }
};
