import React from 'react';

export const Title = ({children, aside}) => {
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 24,
    }}
    >
      <p
        style={{
          fontWeight: 700,
          fontFamily: 'Proxima Nova',
          fontSize: 20,
        }}
      >
        {children}
      </p>
      {aside}
    </div>
  );
};
