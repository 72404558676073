import React from 'react';
import {Button, Popconfirm, Space} from 'antd';
import {WithEllipsis} from 'hoc/WithEllipsis';
import {
  UserOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {blue, green, red, volcano} from '@ant-design/colors';
import {instance} from 'instance';

export const useRender = ({tableRef}) => {
  const deleteNotification = (id) => async () => {
    try {
      await instance.post(`/dashboard/planned/${id}/delete`);
    } finally {
      tableRef.current.getData();
    }
  };

  const renderActions = (_, record) => (
    <Space size="middle">
      <Popconfirm
        title={'Отменить уведомление?'}
        onConfirm={deleteNotification(record.id)}
      >
        <Button
          danger
          type='primary'
        >
              Отменить
        </Button>
      </Popconfirm>
    </Space>
  );

  const renderRecipients = (_, record) => (
    <Space direction='vertical' size='middle'>
      {!!record.target.employees.length && (
        <div style={{display: 'flex'}} >
          <div style={{marginRight: 8}} >
            <UserOutlined style={{fontSize: 24}} />
          </div>
          <WithEllipsis>
            {record.target.employees.map((emp) => emp.name).join(', ')}
          </WithEllipsis>
        </div>
      )}
      {!!record.target.group.length && (
        <div style={{display: 'flex'}} >
          <div style={{marginRight: 8}} >
            <TeamOutlined style={{fontSize: 24}} />
          </div>
          <div>
            <WithEllipsis>
              {record.target.group.map((emp) => emp.name).join(', ')}
            </WithEllipsis>
          </div>
        </div>
      )}
    </Space>
  );

  const renderPeriod = (_, record) => (
    <Space size='large' direction='verical'>
      <div>
        <span style={{
          color: green.primary,
          fontWeight: 500,
        }} >
                Даты:{'\n'}
        </span>

        <div style={{whiteSpace: 'pre'}} >
          {record.period.dates
              .map((date) =>
                moment(date)
                    .format('DD.MM HH:mm'))
              .join('\r\n')
          }
        </div>
      </div>
      {!!record.period.lastDate && (
        <div>
          <span style={{
            color: red.primary,
            fontWeight: 500,
          }} >
                  До:{'\n'}
          </span>

          <div>
            {moment(record.period.lastDate).format('DD.MM HH:mm')}
          </div>
        </div>
      )}
      {!!record.period.isWeekendIncluded && (
        <div>
          <span style={{
            color: volcano.primary,
            fontWeight: 500,
          }} >
                  С выходными
          </span>
        </div>
      )}
      {!!record.period.periodInDays && (
        <div>
          <span style={{
            color: blue.primary,
            fontWeight: 500,
          }} >
                  Каждый:{'\n'}
          </span>

          <div>
            {record.period.periodInDays}
            {renderWord(record.period.periodInDays)} день
          </div>
        </div>
      )}
    </Space>
  );

  function renderWord(number) {
    const lastChar = String(number).slice(-1);
    switch (lastChar) {
      case '1': return '-ый';
      case '2': return '-ой';
      case '3': return '-ий';
      case '4': return '-ый';
      case '5': return '-ый';
      case '6': return '-ой';
      case '7': return '-ой';
      case '8': return '-ой';
      case '9': return '-ый';
      case '0': return '-ый';
    }
  }

  return ({
    renderActions,
    renderRecipients,
    renderPeriod,
  });
};
