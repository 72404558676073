/* eslint-disable react/jsx-no-duplicate-props */
import {Input, Pagination, Table} from 'antd';
import React, {forwardRef, useImperativeHandle} from 'react';
import {useHandleTable} from 'Hooks/useHandleTable';
import qs from 'query-string';
import {useHistory} from 'react-router-dom';

const {Search} = Input;

export const MyTable = forwardRef((props, ref) => {
  const {
    columns,
    endpoint,
    dataField,
    onRow,
    ...restProps
  } = props;

  const history = useHistory();

  const {
    loading,
    limit,
    offset,
    data,
    allData,
    getData,
  } = useHandleTable({dataField, withGetData: true, endPoint: endpoint});

  useImperativeHandle(ref, () => ({
    getData,
  }));

  const currentPage = (offset/limit)+1;

  const onSearch = (value) => {
    const parsedSearh = qs.parse(history.location.search);
    parsedSearh['query'] = value;
    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(parsedSearh),
    });
  };

  const handlePaginationChange = (page, pageSize) => {
    const parsedSearh = qs.parse(history.location.search);
    parsedSearh['offset'] = (page - 1) * pageSize;
    parsedSearh['limit'] = pageSize;
    // eslint-disable-next-line max-len
    history.push(history.location.pathname + '?' + qs.stringify(parsedSearh));
  };

  return (
    <>
      <Search
        placeholder="Введите ключевое слово"
        enterButton="Поиск"
        size="large"
        onSearch={onSearch}
        defaultValue={qs.parse(history.location.search)?.query || ''}
      />
      <div style={{marginBottom: 24}} />

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        loading={loading}
        onRow={onRow}
        onChange={getData}
        pagination={false}
        {...restProps}
        /* pagination={{
          onChange: handlePaginationChange,
          total: data.totalCount || 0,
        }} */
      />
      <div
        style={{
          marginTop: 24,
          marginRight: 0,
          marginLeft: 'auto',
        }}
      >
        <Pagination
          current={currentPage}
          total={allData.totalCount || 0}
          pageSize={10}
          onChange={handlePaginationChange}
          showSizeChanger={false}
        />
      </div>
    </>
  );
});
