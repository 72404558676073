import {Divider} from 'antd';
import React from 'react';

export const WithSectionName = ({children, title, ...dividerProps}) => {
  return (
    <>
      <Divider {...dividerProps}>
        {title}
      </Divider>
      {children}
    </>
  );
};
