/* eslint-disable max-len */
import {Button, Input, notification} from 'antd';
import {instance} from 'instance';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {Title} from 'UI/Title';
import {UsersTransfer} from './UsersTransfer';

const GroupModification = () => {
  const {id} = useParams();
  const history = useHistory();

  const [allUsers, setAllUsers] = useState([]);
  const [group, setGroup] = useState({employees: []});
  const [loading, setLoading] = useState(false);

  const getAllUsers = async () => {
    try {
      const {data} = await instance.get('/dashboard/employees');
      setAllUsers(data.results);
    } catch (e) {
      console.error(e);
    }
  };

  const getData = async () => {
    try {
      const {data} = await instance.get(`/dashboard/group/${id}`);
      setGroup((prevData) => ({
        ...data,
        employees: data.employees.map((i) => i.id),
      }));
    } catch {
      notification.error({message: 'Неизвестная ошибка'});
    }
  };

  useEffect(() => {
    getAllUsers();
    if (id !== '-1' ) {
      getData();
    }
  }, []);

  const handleNameChange = (e) => {
    setGroup((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await instance.post(
          '/dashboard/group/edit',
          {
            id,
            name: group.name || '',
            employeeIds: group.employees,
          },
      );
      setLoading(false);
      notification.success({message: `Группа успешно ${id === -1 ? 'создана' : 'отредактирована'}`});
      history.push('/groups');
    } catch (e) {
      notification.error({message: `Ошибка ${id === -1 ? 'создания' : 'редактирования'}`});
      setLoading(false);
    }
  };


  return (
    <div>
      <Title
        aside={
          <Button
            size='large'
            style={{
              background: '#52c41a',
              color: 'white',
              width: 200,
            }}
            disabled={loading}
            onClick={handleSave}
          >
            {loading ? 'Подождите...' : 'Сохранить'}
          </Button>
        }
      >
        {id > -1 ? 'Изменить' : 'Добавить'} группу
      </Title>

      <Input
        placeholder="Название группы"
        allowClear
        value={group?.name || ''}
        size='large'
        style={{width: 540, marginBottom: 24}}
        onChange={handleNameChange}
      />

      <UsersTransfer
        setGroup={setGroup}
        group={group}
        allUsers={allUsers}
      />

    </div>
  );
};

export default GroupModification;
