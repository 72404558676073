import {Button, Col, Row, Spin} from 'antd';
import Search from 'antd/lib/input/Search';
import {deleteNotificationRequest} from 'api/notifications';
import {NotificationItem} from 'Components/NotificationItem/NotificationItem';
import {
  SendDirectNotificationModal,
} from 'Components/SendDirectNotificationModal/SendDirectNotificationModal';
import {useTableSearch} from 'Hooks/useTableSearch';
import qs from 'query-string';
import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {Title} from 'UI/Title';
import {handleSearch} from 'utils/_uncategorized';


const NotificationsPage = () => {
  const history = useHistory();
  const [sendNotificationId, setSendNotificationId] = useState(null);
  const [currentCardLoading, setCurrentCardLoading] = useState(null);

  const {
    loading,
    data,
    getData,
  } = useTableSearch({
    endPoint: '/dashboard/notification',
  });

  const handleEdit = useCallback((editId) =>
    history.push(`/notifications/${editId}`), []);

  const handleDelete = useCallback(async (deleteId) => {
    setCurrentCardLoading(deleteId);
    try {
      await deleteNotificationRequest(deleteId);
    } finally {
      setCurrentCardLoading(deleteId);
    }
    getData();
  }, [getData]);

  const handleCreateClick = useCallback(() => {
    history.push('/notifications/-1');
  }, []);

  const handleSendNotification = (notId) => {
    setSendNotificationId(notId);
  };

  console.log(sendNotificationId);

  return (
    <div>
      <Title
        aside={
          <Button
            size='large'
            style={{background: '#52c41a', color: 'white', width: 200}}
            onClick={handleCreateClick}
          >
          Создать
          </Button>
        }
      >
        Уведомления
      </Title>

      <Search
        placeholder="Введите ключевое слово"
        enterButton="Поиск"
        size="large"
        onSearch={handleSearch(history)}
        defaultValue={qs.parse(history.location.search)?.query || ''}
      />
      <div style={{marginBottom: 24}} />

      {loading ?
      <div style={{textAlign: 'center'}} ><Spin size='large' /></div> :
      (
        <Row gutter={[24, 24]}>
          {data.map((notification) => (
            <Col key={notification.id} span={8} >
              <NotificationItem
                loading={currentCardLoading === notification.id}
                notification={notification}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleSendNotification={handleSendNotification}
              />
            </Col>
          ))}
        </Row>
      )
      }


      <SendDirectNotificationModal
        notificationId={sendNotificationId}
        setNotificationId={setSendNotificationId}
      />
    </div>
  );
};

export default NotificationsPage;
